@import "/src/styles/variables.module.scss";
@import "/src/styles/mixins.module.scss";

@font-face {
    font-family: "SF Pro Display";
    src:
        url("./SFPRODISPLAYREGULAR.OTF") format("opentype"),
        url("./SFPRODISPLAYBOLD.OTF") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@mixin for-phone-only {
    @media (max-width: 599px) {
        @content;
    }
}

html body {
    min-height: 100%;
    margin: 0;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    position: relative;
    height: 100%;
    background: linear-gradient(126deg, #030316 28.82%, #0d0a45 117.87%);
    background-blend-mode: darken;
}

.terms {
    background: #010101;
    background-blend-mode: darken;
    position: relative;

    &__back {
        position: absolute;
        width: 100%;
        opacity: 1;
        z-index: 0;

        img {
            width: 100%;
        }

        @include for-phone-only {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 0;
        }
    }

    &__head {
        padding: 40px;
        z-index: 100000;
    }

    &__main {
        display: flex;
        align-items: center;
        flex-direction: column;

        article {
            padding: 16px;
        }

        &__title {
            color: #a7b0c8;
            text-align: center;
            font-family: "SF Pro Display";
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 1px;

            margin: 0;
            margin-bottom: 20px;
        }

        &__content {
            width: 90%;
            margin-top: 80px;
            color: #e0e2e7;
            text-align: justify;
            font-family: "SF Pro Display";
            font-size: 26px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            li {
                margin-bottom: 16px;
            }
        }
    }
}
